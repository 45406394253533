import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const AGB = () => {
  return (
    <div>
      <Layout>
        <div className="container mx-auto">
          <Seo title="Impressum" />
          <br />
          <br />
          <h1>
            <strong>
              <span>
                Allgemeine Gesch&auml;ftsbedingungen (AGB) des Kleinunternehmens
                &bdquo;Zweitblick&ldquo;-Lektorat
              </span>
            </strong>
          </h1>
          <p>
            <span>&nbsp;</span>
          </p>
          <p className="text-blue-600">
            <a href="/datenschutz">Zur Datenschutzerklärung</a>{" "}
          </p>
          <br />
          <br />
          <p>
            <strong>
              <span>1 Allgemeines</span>
            </strong>
          </p>
          <br />
          <p>
            <span>
              Die Allgemeinen Gesch&auml;ftsbedingungen (AGB) gelten f&uuml;r
              alle Leistungen und den gesamten Gesch&auml;ftsverkehr zwischen
              der/dem Auftraggeber/in und dem Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat. Der/die Auftraggeber/in erkennt
              mit der Erteilung des Auftrages durch eine
              Auftragsbest&auml;tigung (per E-Mail) diese AGB an. Werden die AGB
              einmal im Verh&auml;ltnis zwischen dem/der Auftraggeber/in und dem
              Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat wirksam, dann
              darf sich &bdquo;Zweitblick&ldquo;-Lektorat auch in
              zuk&uuml;nftigen F&auml;llen eines rechtsgesch&auml;ftlichen
              Kontaktes auf deren Geltung berufen, ohne dass ein Nachweis
              gef&uuml;hrt wird, dass die AGB nachgeschaltet abermals wirksam
              vereinbart wurden.
            </span>
          </p>
          <p>
            <span>
              Gesch&auml;ftsbedingungen der Auftraggeberin oder des
              Auftraggebers gelten hingegen nur dann, wenn dies von dem
              Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat
              ausdr&uuml;cklich schriftlich best&auml;tigt wird. Von diesen AGB
              abweichende Individualvereinbarungen k&ouml;nnen
              ausschlie&szlig;lich schriftlich zwischen dem Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat und dem/der Auftraggeber/in
              vereinbart werden.
            </span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span>2 Angebot</span>
            </strong>
          </p>
          <br />
          <p>
            <span>
              Ein Angebot (inkl. Kostenvoranschlag) wird nach Zusendung einer
              Textprobe und allen n&ouml;tigen Informationen zu dem zu
              korrigierenden Text (inkl. Seiten- und Zeichenanzahl) von dem
              Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat nach bestem
              Fachwissen erstellt und dem/der Auftraggeber/in &uuml;bermittelt.
              Die Erstellung des Angebotes erfolgt unentgeltlich. Angebote sind
              f&uuml;r den Kunden bis zur Auftragserteilung unverbindlich.
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              Der Preis pro Normseite des zu korrigierenden Werkes richtet sich
              nach dem Arbeits- und Korrekturaufwand, der anhand des Probetextes
              f&uuml;r das gesamte Werk zu erwarten ist. Der im Angebot
              angegebene Preis pro Normseite ist verbindlich.&nbsp;
            </span>
            <span>&nbsp;</span>
            <span>
              Gew&auml;hrte Preise und Konditionen gelten nur f&uuml;r den
              jeweiligen Auftrag.
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              Sollte sich nach Auftragserteilung eine betr&auml;chtliche
              &Uuml;berschreitung des urspr&uuml;nglich ermittelten
              Arbeitsaufwandes ergeben, die eine erhebliche Erh&ouml;hung des
              Honorars zur Folge h&auml;tte, und die anhand des
              &uuml;bermittelten Probetextes f&uuml;r das Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat nicht vorhersehbar war, wird das
              Unternehmen den/die Auftraggeber/in unverz&uuml;glich davon
              verst&auml;ndigen und ihm/ihr ein neuerliches Angebot vorlegen.
              Kommt es zwischen den Vertragsparteien zu keiner neuerlichen
              Einigung, hat jede Vertragspartei das Recht, von dem
              urspr&uuml;nglichen Vertrag zur&uuml;ckzutreten. Leistungen, die
              bis zur Vertragsaufl&ouml;sung bereits von dem Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat erbracht wurden, sind
              entsprechend des im urspr&uuml;nglichen Angebot vereinbarten
              Preises von der/dem Auftraggeber/in zu bezahlen.
            </span>
          </p>
          <p>
            <span>
              Dem Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat steht es
              jederzeit frei, Auftr&auml;ge ohne Angabe von Gr&uuml;nden
              abzulehnen.
            </span>
          </p>
          <br />
          <br />
          <p>
            <strong>
              <span>3 Zustandekommen des Vertrags</span>
            </strong>
          </p>
          <br />
          <p>
            <span>
              Der Vertrag zwischen dem/der Auftraggeber/in und dem
              Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat kommt zustande,
              wenn der/die Auftraggeber/in das von dem Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat abgegebene Angebot durch
              schriftliche Erkl&auml;rung (per E-Mail) angenommen und eine
              Anzahlung von 50% des im Angebot festgelegten Preises entrichtet
              hat.
            </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              F&uuml;r die Art und den Umfang der Leistung gelten
              ausschlie&szlig;lich die schriftlich im Angebot vereinbarten
              Leistungen und die vorliegenden AGB.
            </span>
          </p>
          <p>
            <span>
              Der/die Auftraggeber/in verpflichtet sich, seinen/ihren korrekten
              Namen und seine/ihre korrekte Anschrift dem Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat mitzuteilen.
            </span>
          </p>
          <br />
          <br />
          <p>
            <strong>
              <span>4 Leistungen</span>
            </strong>
          </p>
          <br />
          <p>
            <span>
              (a) Das Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat
              erbringt Dienstleistungen in den Bereichen Korrektorat und
              Lektorat von deutsch- und englischsprachigen Texten.
            </span>
          </p>
          <br />
          <p>
            <span>
              (b) Das Korrektorat umfasst die &Uuml;berpr&uuml;fung von Texten
              hinsichtlich Rechtschreibung, Grammatik, Zeichensetzung und
              Layout.
            </span>
          </p>
          <br />
          <p>
            <span>
              (c) Das Lektorat ist umfangreicher als das Korrektorat und umfasst
              zus&auml;tzlich zum Korrektorat die &Uuml;berpr&uuml;fung des
              Aufbaus, der Gliederung, der Verst&auml;ndlichkeit und des Stils
              des Textes in mindestens einem intensiven Lektoratsdurchgang
              (abh&auml;ngig von der gebuchten Leistung). Auf Wunsch der
              Auftraggeberin oder des Auftraggebers wird der Text von dem
              Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat auch auf eine
              gendergerechte Schreibweise &uuml;berpr&uuml;ft.
            </span>
          </p>
          <br />
          <p>
            <span>
              (d) Das 360-Grad-Lektorat umfasst neben zwei intensiven
              Lektoratsdurchg&auml;ngen ein pers&ouml;nliches
              Video-Beratungsgespr&auml;ch zwischen dem/der Auftraggeber/in und
              dem Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat, genauer
              gesagt der Inhaberin des Unternehmens Lea Gremm. Das Gespr&auml;ch
              ist begrenzt auf eine Dauer von 45 Minuten.
            </span>
          </p>
          <br />
          <p>
            <span>
              (e) Alle Dienstleistungen erfolgen f&uuml;r Texte in deutscher
              oder englischer Sprache. Das Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat verpflichtet sich, die
              Leistungen sorgf&auml;ltig und nach bestem Wissen und Gewissen zu
              erbringen. Eine hundertprozentige Fehlerfreiheit kann jedoch nicht
              garantiert werden.
            </span>
          </p>
          <br />
          <p>
            <span>
              (f) Der/die Auftraggeber/in ist verpflichtet, die zu
              korrigierenden Texte im angegebenen Umfang (inkl. alle
              erforderlichen Hintergrundinformationen) dem Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat rechtzeitig zur Verf&uuml;gung
              zu stellen, um eine fristgerechte Lieferung zu gew&auml;hrleisten.
              Hinsichtlich der Frist f&uuml;r die Lieferung des
              korrigierten/lektorierten Textes sind die beiderseitigen
              schriftlichen Erkl&auml;rungen ma&szlig;geblich. Werden die oben
              genannten Voraussetzungen von der Auftraggeberin bzw. dem
              Auftraggeber nicht rechtzeitig erf&uuml;llt, so verl&auml;ngert
              sich die Lieferfrist angemessen.
            </span>
          </p>
          <br />
          <p>
            <span>
              (g) Die korrigierten/lektorierten Texte werden unmittelbar nach
              Fertigstellung und Qualit&auml;tskontrolle fristgerecht an den/die
              Auftraggeber/in per E-Mail versendet. Die Lieferfrist gilt als
              eingehalten, sobald die bearbeiteten Dokumente in den
              elektronischen Versand gegeben wurden.
            </span>
          </p>
          <br />
          <p>
            <span>
              (h) Sollten sich w&auml;hrend des laufenden Auftrages
              Ver&auml;nderungen ergeben, die auf die Durchf&uuml;hrung Einfluss
              nehmen, ist das Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat
              unverz&uuml;glich dar&uuml;ber zu informieren. Nachtr&auml;gliche
              &Auml;nderungen der in Auftrag gegebenen Texte bed&uuml;rfen einer
              neuen schriftlichen Auftrags- und Terminbest&auml;tigung.
            </span>
            <span>&nbsp;</span>
            <span>
              Dem Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat steht es
              frei, neue schriftliche Auftr&auml;ge ohne Angabe von Gr&uuml;nden
              abzulehnen.
            </span>
          </p>
          <br />
          <p>
            <span>
              (i) Ist nichts anderes vereinbart, so verbleiben die von der/dem
              Auftraggeber/in des Kleinunternehmens
              &bdquo;Zweitblick&ldquo;-Lektorat zur Verf&uuml;gung gestellten
              Unterlagen nach Abschluss des Korrektur-/Lektorats-auftrages bei
              dem Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat. Dieses hat
              keine Verpflichtung zur Aufbewahrung oder sonstigem Umgang damit.
              Das Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat hat jedoch
              daf&uuml;r zu sorgen, dass diese Unterlagen nicht vertragswidrig
              verwendet und nicht an Dritte weitergegeben werden.
            </span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span>5 Honorare und Bezahlung</span>
            </strong>
          </p>
          <br />
          <p>
            <span>
              (a) Honorare werden individuell je nach Dienstleistung in
              schriftlicher Form vereinbart. Der/die Auftraggeber/in
              verpflichtet sich mit der schriftlichen Annahme eines Angebots
              (per E-Mail), eine Anzahlung in H&ouml;he von 50% des vereinbarten
              Preises zu entrichten. Erst nach Eingang dieser ersten Teilzahlung
              auf dem Konto des Kleinunternehmens
              &bdquo;Zweitblick&ldquo;-Lektorat gilt das von Seiten des
              Unternehmens gemachte Angebot f&uuml;r beide Seiten als
              verbindlich. Der/die Auftraggeber/in verpflichtet sich
              au&szlig;erdem, die zweite Teilzahlung in H&ouml;he von 50% des
              vereinbarten Preises nach fristgerechter Lieferung der
              korrigierten/lektorierten Texte innerhalb eines Zeitraums von 14
              Tagen zu entrichten. Im Fall des Zahlungsverzuges schuldet der/die
              Auftraggeberin Verzugszinsen in gesetzlicher H&ouml;he.
            </span>
          </p>
          <br />
          <p>
            <span>
              (b) Da das Unternehmen das Kleinunternehmens
              &bdquo;Zweitblick&ldquo;-Lektorat der Kleinunternehmerregelung
              unterliegt, wird keine Umsatzsteuer verrechnet (gem. &sect; 6 Abs.
              1 Z 27 UstG).
            </span>
          </p>
          <br />
          <p>
            <span>
              (c) Tritt der/die Auftraggeber/in nach Erteilung der
              Auftragsbest&auml;tigung und &Uuml;berweisung der ersten
              Teilzahlung (in H&ouml;he von 50% des vereinbarten Preises) oder
              w&auml;hrend oder nach Bearbeitung der Texte vom Auftrag
              zur&uuml;ck, so fallen f&uuml;r den/die Auftraggeber/in
              w&auml;hrend der Korrektur 50 % (erste Teilzahlung) und nach der
              Korrektur 100 % (erste und zweite Teilzahlung) des vereinbarten
              Preises an. Diese Betr&auml;ge sind innerhalb von 14 Tagen nach
              R&uuml;cktritt f&auml;llig. Im Fall des Zahlungsverzuges schuldet
              der/die Auftraggeberin Verzugszinsen in gesetzlicher H&ouml;he.
            </span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span>6 M&auml;ngel, Haftung und Reklamation</span>
            </strong>
          </p>
          <br />
          <p>
            <span>
              (a) Ziel eines Korrektorats bzw. Lektorats ist die
              h&ouml;chstm&ouml;gliche Reduzierung der Fehler im Auftragstext.
              Das Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat
              verpflichtet sich, alle Korrektur- und Lektoratsleistungen so
              sorgf&auml;ltig auszuf&uuml;hren, dass sich im Text m&ouml;glichst
              keine Fehler mehr befinden.&nbsp;Eine Garantie f&uuml;r eine
              hundertprozentige Fehlerfreiheit ist jedoch grunds&auml;tzlich
              immer ausgeschlossen.
            </span>
          </p>
          <br />
          <p>
            <span>
              (b) Das Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;haftet nur bei Vorsatz oder
              grober Fahrl&auml;ssigkeit. Die Haftungsh&ouml;chstgrenze
              entspricht dem im Angebot vereinbarten und in Rechnung gestellten
              Betrag. Eine Haftung f&uuml;r entgangenen Gewinn,
              Folgesch&auml;den o. &Auml;., etwa aus inhaltlicher
              Fehlerhaftigkeit, wird ausgeschlossen. Der/die Auftraggeber/in
              kann keinerlei Schadensersatzanspr&uuml;che gegen&uuml;ber dem
              Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat geltend machen.
              Das Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;haftet
              nicht f&uuml;r mittelbare Sch&auml;den, die durch eine fehlerhafte
              Korrektur entstehen, auch nicht f&uuml;r Verz&ouml;gerungen oder
              Ausf&uuml;hrungsm&auml;ngel, die durch eine unklare, unrichtige
              oder unvollst&auml;ndige Auftragserteilung entstehen.
            </span>
          </p>
          <br />
          <p>
            <span>
              (c) Ein nach Auffassung des Auftraggebers bzw. der Auftraggeberin
              mangelhaft erf&uuml;llter Auftrag ist umgehend, sp&auml;testens
              jedoch innerhalb von sieben Tagen nach Erhalt des korrigierten
              Textes, gegen&uuml;ber dem Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat schriftlich (per E-Mail) zu
              reklamiert. M&auml;ngel m&uuml;ssen dabei in hinreichender Form
              nachgewiesen werden. Erfolgt innerhalb dieser Frist kein
              schriftlicher Einwand, so gilt der Auftrag als
              vertragsgem&auml;&szlig; durchgef&uuml;hrt. Werden berechtigte
              M&auml;ngel innerhalb der Frist reklamiert, verpflichtet sich das
              Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat zur
              Verbesserung dieser innerhalb einer angemessenen Frist (mindestens
              sieben Tage).&nbsp;
            </span>
          </p>
          <br />
          <p>
            <span>
              (d) Am Text durchgef&uuml;hrte stilistische Korrekturen sowie
              Vorschl&auml;ge/Kommentare bez&uuml;glich der
              Verst&auml;ndlichkeit, Struktur und Inhalt des Textes verstehen
              sich als Verbesserungsvorschl&auml;ge. Sie bed&uuml;rfen daher der
              abschlie&szlig;enden &Uuml;berpr&uuml;fung durch den/die
              Auftraggeber/in, werden nicht als M&auml;ngel anerkannt und sind
              deshalb als Reklamationsgrund ausgeschlossen. F&uuml;r sachliche,
              fachliche oder inhaltliche M&auml;ngel, die das Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat nicht zu vertreten hat, wird
              nicht gehaftet.
            </span>
          </p>
          <br />
          <p>
            <span>
              (e) Das Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;haftet nicht f&uuml;r
              Daten, die auf elektronischem Weg widerrechtlich in die H&auml;nde
              von Dritten gelangen oder nach der &Uuml;bermittlung Sch&auml;den,
              M&auml;ngel und Beeintr&auml;chtigungen aufweisen. Das
              Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;haftet
              nicht f&uuml;r Sch&auml;den oder den Verlust von Daten (bspw.
              durch Viren, Sch&auml;dlingsprogramme, Inkompatibilit&auml;t in
              Hard- und Software, Postweg etc.). Eine Haftung f&uuml;r
              Softwaresch&auml;den, die durch den Gebrauch der Texte entstehen,
              ist ebenfalls ausgeschlossen. Das Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;haftet nicht f&uuml;r die
              rechtliche Zul&auml;ssigkeit der zu korrigierenden bzw.
              lektorierenden Texte. Sieht sich das Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;erst nach Vertragsabschluss
              mit solchen Inhalten konfrontiert, so hat dieses das Recht, sofort
              vom Vertrag zur&uuml;ckzutreten. Bis dahin erbrachte Leistungen
              zahlt der/die Auftraggeber/in in vollem Umfang.
            </span>
          </p>
          <br />
          <p>
            <span>
              (f) F&uuml;r den Fall der h&ouml;heren Gewalt hat das das
              Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat den/die
              Auftraggeber/in unverz&uuml;glich zu benachrichtigen. H&ouml;here
              Gewalt berechtigt sowohl das Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;als auch den/die
              Auftraggeber/in, von dem Vertrag zur&uuml;ckzutreten. Der/die
              Auftraggeber/in hat dem Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;jedoch Ersatz f&uuml;r
              bereits get&auml;tigte Aufwendungen bzw. Leistungen zu leisten.
              Als h&ouml;here Gewalt gilt der Eintritt unvorhersehbarer
              Hindernisse, die nachweislich die M&ouml;glichkeit des
              Kleinunternehmens &bdquo;Zweitblick&ldquo;-Lektorat, den Auftrag
              vereinbarungsgem&auml;&szlig; zu erledigen, entscheidend
              beeintr&auml;chtigen.
            </span>
          </p>
          <br />
          <br />
          <p>
            <strong>
              <span>7 Drittunternehmen und Hilfsmittel</span>
            </strong>
          </p>
          <p>
            <span>
              Das Kleinunternehmen &bdquo;Zweitblick&ldquo;-Lektorat ist
              berechtigt, andere Unternehmen und/oder Personen beizuziehen,
              sofern dies f&uuml;r die Erbringung der Leistung erforderlich ist.
              Diese werden von dem Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;hinsichtlich ihrer
              Qualit&auml;ten gepr&uuml;ft. Es erfolgt keine Anzeige an den/die
              Kunden/in, da sich das Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;alleine f&uuml;r die
              Dienstleistung verantwortlich zeichnet. Zur Erbringung der
              Dienstleistung kann das Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;technische Hilfsmittel
              einsetzen.
            </span>
          </p>
          <p>
            <span>&nbsp;</span>
          </p>
          <p>
            <strong>
              <span>8 Lieferung</span>
            </strong>
          </p>
          <p>
            <span>
              Die Lieferung erfolgt, soweit nicht anders vereinbart, per E-Mail.
              Die Lieferfrist wird zwischen den beiden Vertragsparteien
              schriftlich in einem Angebot vereinbart. Bei absehbaren
              Lieferverz&ouml;gerungen ist das Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;verpflichtet, den/die
              Auftraggeber/in umgehend zu informieren. Der/die Auftraggeber/in
              ist in diesem Fall berechtigt, den Auftrag zur&uuml;ckzuziehen.
              Bis dahin erbrachte Leistungen sind in voller H&ouml;he zu
              bezahlen (siehe Honorare und Bezahlung).
            </span>
          </p>
          <br />
          <br />
          <p>
            <strong>
              <span>9 Vertraulichkeit</span>
            </strong>
          </p>
          <p>
            <span>
              Das Kleinunternehmen
              &bdquo;Zweitblick&ldquo;-Lektorat&nbsp;verpflichtet sich, alle von
              der/dem Auftraggeber/in &uuml;bermittelten Texte, Daten und
              Informationen vertraulich zu behandeln und sie f&uuml;r keine
              anderen Zwecke als zur Vertragserf&uuml;llung zu verwenden.
            </span>
          </p>
          <br />
          <br />
          <p>
            <strong>
              <span>10 Gerichtsstand und Rechtswahl</span>
            </strong>
          </p>
          <p>
            <span>
              Gerichtsstand ist der Sitz des Kleinunternehmens
              &bdquo;Zweitblick&ldquo;-Lektorat. Der Vertrag unterliegt
              ausschlie&szlig;lich dem Recht der Republik &Ouml;sterreich.
            </span>
          </p>
          <br />
          <br />

          <br />
          <p>
            <span>&nbsp;</span>
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </Layout>
    </div>
  )
}

export default AGB
